@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');


* {
  font-family: 'Poppins', sans-serif;
}

body,
html{
  margin: 0;
  padding: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
.google-map{
  width: 100%;
  height: 100%;
}

.ant-picker-cell:not(.ant-picker-cell-disabled) {
  color: #000000d9!important;
}

 .ant-picker-cell-selected .ant-picker-cell-inner {
  background: #338AFF !important;
  border-radius: 50px !important;
}

 .ant-picker-cell-in-view .ant-picker-cell-inner:before {
  border-color: #338AFF !important;
}

.ant-modal-root .ant-modal-wrap {
  z-index: 1005;

  .ant-modal {
    width: 480px!important;

    @media screen and (max-width: 480px) {
      width: calc(100vw - 15px) !important;
    }
    }

  .ant-modal-content {
    border-radius: 20px;

    .ant-modal-body {
      padding: 25px;

      .ant-modal-confirm-title {
        font-size: 16px;
      }

      .ant-modal-confirm-content {
        margin-top: 15px;
      }

      .ant-modal-confirm-btns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 30px;

        .ant-btn {
          height: 40px;
          width: 30%;
          border-radius: 50px;

          &.ant-btn-primary {
            margin-left: 15px;
            background-color: #338AFF ;
            border: 1px solid #338AFF ;
            color: #fff;

            &:hover {
              opacity: .9;
            }
          }
          &.ant-btn-default {
            &:hover, &:focus {
              border: 1px solid #338AFF ;
              color: #338AFF ;
            }
          }
        }
      }
    }
  }
}

.drag-project-item{
  z-index: 100;
}

.row-dragging .ant-table-cell,
.mobile-row-dragging .ant-table-cell{
  padding: 16px 0 0 16px;
  visibility: hidden;
  overflow: hidden;
}

.row-dragging .drag-visible,
.mobile-row-dragging .drag-visible{
  visibility: visible;
  background-color: #fff;
}

.row-dragging .ant-table-cell:nth-child(2),
.mobile-row-dragging .ant-table-cell:nth-child(3){
  width:100% ;
  & .ant-typography{
    width: auto;
  }
}

.menuWrapper {
    border-radius: 15px !important;
    margin-top: 15px;
    width: 190px;
    padding: 10px 0;

    li {
      padding: 0;
    }
    span {
      padding: 10px 20px;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
    }
    .ant-dropdown-menu-item:hover {
      background: none;
      color: #338aff;
    }

    &.tools {
      width: 220px;
    }
}


.floating-label{
  position: absolute;
  left: 20px;
  top: 25px;
  transform: translate(0,-50%);
  transition: 0.2s ease all;
  height: 20px;
  display: block;
  background: white;
  padding: 0 2px;
  pointer-events: none;
  //width: 78%;
  //width: 65%;
  width: calc(100% - 80px);
  z-index: 1;
  & span{
    position: relative;
    display: block;
    z-index:2;
    line-height: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
  }
}
.floating-label-active{
  top: -2px;
  width: fit-content;
  max-width: 60%;
  left: 20px;
  height: 15px !important;
  background: transparent;
  & span{
    opacity: 1;
    font-size: 11px;
    height: fit-content;
    z-index: 2 !important;
  }
  &::before{
    content: '';
    z-index: 0;
    position: absolute;
    background: white;
    top: 7px;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 50%;
  }
}



.ant-form-item-has-error input{
  border-color: red !important;
}
.ant-form-item-has-error .css-yk16xz-control{
  border-color: red !important;
}

.css-yk16xz-control{
  border:1px solid red
}

.ant-form-item-control-input-content [class$='-menu'] {
  z-index: 5;
}

.rc-virtual-list-holder::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}
.rc-virtual-list-holder::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0,0,0,.3);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.info_tooltip {

  .ant-tooltip-inner {
    border-radius: 10px;
    padding: 10px 12px;
  }
}


.footer-content {
  .contacts-wrapper {
    width: 100%;
    margin: 0 0 20px 0 !important;
    max-width: 260px;
    position: fixed;
    bottom: 210px;
  }

  .chat_wrapper {
    max-width: 260px;
    position: fixed;
    bottom: 20px;

    img {
      width: 100%;
    }

    .clickable_content {
      position: absolute;
      bottom: 18px;
      width: calc(100% - 30px);
      margin-left: 15px;
      height: 36px;
      cursor: pointer;
    }

    @media screen and (max-width: 992px) {
      position: relative;
      bottom: 0;
      margin-top: 20px;
    }

    &.selected_app {
      position: relative;
      margin: 20px;
    }
  }

  &.new-app {
    @media screen and (max-width: 992px) {
      position: relative!important;
      margin-top: 5px;

      .contacts-wrapper {
        position: relative!important;
        bottom: 0;
      }
    }
  }
}

.burger {
  display: none;
  width: 24px;
  cursor: pointer;
  position: relative;
  z-index: 99;

  .burger-item {
    width: 24px;
    height: 4px;
    margin-bottom: 4px;
    background: #171717;
    border-radius: 4px;
    transition: all ease 0.3s;
  }

  &.collapsed .burger-item {
    transform: rotate(45deg) translate(-6px, -5px);

    &:nth-last-child(3) {
      transition: none;
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    &:nth-last-child(2) {
      transform: rotate(-45deg) translate(-1px, -1px);
    }
  }

  @media screen and (max-width: 992px) {
    display: block;
  }
}
